import TiptapCollab from "../components/TiptapCollab.jsx";
import DocList from "../components/DocList.jsx";

const EditorPage = () => {
  return (
    <div>
      {/* <DocList/> */}
      <TiptapCollab />
    </div>
  );
};

export default EditorPage;
