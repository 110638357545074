import React, { useState, useEffect } from "react";
import {
  EditorProvider,
  FloatingMenu,
  BubbleMenu,
  EditorContent,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import axios from "axios";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import { useCurrentEditor } from "@tiptap/react";
import Markdown from "react-markdown";
import agentSuggestion from "../agentSuggestion";
import Mention from "@tiptap/extension-mention";
import { Form, useLoaderData } from "react-router-dom";
import { mergeAttributes } from "@tiptap/core";

import BodyOfKnowledgeView from "./BodyOfKnowledgeView";

import Collaboration from "@tiptap/extension-collaboration";
import * as Y from "yjs";

import { TiptapCollabProvider } from "@hocuspocus/provider";
import { Button } from "@headlessui/react";
import { ClickableMention } from "../lib/tiptapMentions";
import unicornAPI from "../lib/unicornAPI";
import {
  logingUrlWithParams,
  assertLoginError,
  clientCall,
} from "../hooks/useAuth";

const content =
  "<h1>Hello Universe!</h1><p>Name's Unicorn, at your service.</p>";

const SlotAfter = ({ editor, onSetToken }) => {
  const [mdPreview, setMdPreview] = useState("");

  const apiAct = () => {
    unicornAPI
      .post("/actuate", {
        inputs: {
          endgame: {
            steps: 5,
            solutions: 1,
          },
          registry: {
            // stage: 'real',
            stage: "test",
            policy: "WeightedRandomPolicy",
            acting_agent: {
              cls: "EpsilonGreedyAgent",
              args: {
                active: true,
                initial_weight: 1.0,
              },
            },
            learning_agent: null,
          },
          ctx: {
            planner_model_id: "gpt-3.5-turbo",
            // embedding_model_id: "text-embedding-3-small",
            embedding_model_id: "random-128",
          },
          seed: 35,
          mangame: null,
        },
        prosemirror_document: editor.getJSON(),
      })
      .then((response) => {
        editor.commands.setContent(response.data.prosemirror_document);
        setMdPreview(response.data.markdown);
      })
      .catch((error) => {
        assertLoginError(error);
      });
  };

  const apiBodyOfKnowledge = () => {
    unicornAPI
      .post("/body_of_knowledge/markdown", {
        prosemirror_document: editor.getJSON(),
      })
      .then((response) => {
        setMdPreview(response.data.markdown);
      })
      .catch((error) => {
        assertLoginError(error);
      });
  };

  const prosemirrorDocToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(editor.getJSON(), null, 2));
  };

  const htmlToClipboard = () => {
    navigator.clipboard.writeText(editor.getHTML());
  };

  const setToken = (e) => {
    e.preventDefault();
    onSetToken(e.target.value);
  };

  return (
    <div className="bg-gray">
      <div className="flex flex-row flex-wrap">
        <Button
          className="basis-1/4 py-2 px-4 text-sm text-black/70 data-[hover]:bg-opacity-75 data-[active]:bg-green-700 data-[hover]:underline"
          onClick={apiAct}
        >
          Actuate
        </Button>
        <Button
          className="basis-1/4 py-2 px-4 text-sm text-black/70 data-[hover]:bg-opacity-75 data-[active]:bg-green-700 data-[hover]:underline"
          onClick={apiBodyOfKnowledge}
        >
          Update Body Of Knowledge
        </Button>
        <Button
          className="basis-1/4 py-2 px-4 text-sm text-black/70 data-[hover]:bg-opacity-75 data-[active]:bg-green-700 data-[hover]:underline"
          onClick={prosemirrorDocToClipboard}
        >
          Copy ProseMirror Doc
        </Button>
        <Button
          className="basis-1/4 py-2 px-4 text-sm text-black/70 data-[hover]:bg-opacity-75 data-[active]:bg-green-700 data-[hover]:underline"
          onClick={htmlToClipboard}
        >
          Copy HTML
        </Button>
      </div>
      {mdPreview ? <BodyOfKnowledgeView mdPreview={mdPreview} /> : null}
    </div>
  );
};

export async function loader({ params }) {
  return { documentName: params.documentName };
}

const TiptapCollab = () => {
  const [jwt, setJwt] = useState();
  const doc = new Y.Doc();
  const { documentName } = useLoaderData();

  useEffect(() => {
    if (!jwt) {
      unicornAPI
        .get("/tiptap/authenticate")
        .then((response) => {
          console.log("JWT!!!", response.data);
          setJwt(response.data.jwt);
        })
        .catch((error) => {
          assertLoginError(error);
        });
    }
  }, []);

  useEffect(() => {
    if (!!jwt) {
      const provider = new TiptapCollabProvider({
        name: documentName,
        appId: "89j47797",
        token: jwt,
        document: doc,

        // The onSynced callback ensures initial content is set only once using editor.setContent(), preventing repetitive content insertion on editor syncs.
        onSynced() {
          console.log("Synced!", editor);
          if (!doc.getMap("config").get("initialContentLoaded") && editor) {
            doc.getMap("config").set("initialContentLoaded", true);

            editor.commands.setContent(content);
          }
        },
      });

      return () => {
        provider.destroy();
      };
    }
  }, [documentName, jwt]);

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        ClickableMention.configure({
          suggestion: agentSuggestion,
        }),
        Collaboration.configure({
          document: doc,
        }),
      ],
      editorProps: {
        attributes: {
          class: "my-2 p-2 prose max-w-none prose-sm prose-neutral",
        },
      },
    },
    [documentName],
  );

  if (!jwt) {
    return (
      <>
        <div className="container my-2 mx-auto">
          <p>Loading...</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container my-2 mx-auto">
        <EditorContent editor={editor} className="">
          <FloatingMenu>This is the floating menu</FloatingMenu>
          <BubbleMenu>This is the bubble menu</BubbleMenu>
        </EditorContent>
      </div>
      <SlotAfter editor={editor} onSetToken={setJwt} />
    </>
  );
};

export default TiptapCollab;
