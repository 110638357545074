import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { unicornClient } from "../lib/unicornAPI";
import { getCookie, setCookie, delCookie } from "../lib/cookies";

const AuthContext = createContext();
const loginUrl = process.env.REACT_APP_OAUTH2_LOGIN_URL;
const logoutUrl = process.env.REACT_APP_OAUTH2_LOGOUT_URL;
const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
const redirectUrl = process.env.REACT_APP_OAUTH2_CALLBACK_URL;

export const logingUrlWithParams = `${loginUrl}?client_id=${clientId}&response_type=token&redirect_uri=${redirectUrl}&state=abc123`;

export const assertLoginError = (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    window.location = logingUrlWithParams;
  }
};

export const clientCall = async (future) => {
  // A wrapper for client calls to ensure status code and redirects in one place.
  // This ideally should exist in axios interceptors, but interceptors don't catch 4xx errors when body is empty (which is valid).
  let response;
  try {
    console.log("Calling Unicorn...");
    response = await future;
  } catch (error) {
    assertLoginError(error);
  }

  return response?.data;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getCookie("token"));
  const [headerSet, setHeaderSet] = useState(false);

  useEffect(() => {
    if (!!token) {
      unicornClient.defaults.headers.common["Authorization"] = token;
      console.log("Set auth headers");
    } else {
      delete unicornClient.defaults.headers.common["Authorization"];
    }
    setHeaderSet(true);
  }, [token]);

  const handleLogin = ({ token, tokenType }) => {
    console.log("Handling login...");
    const authHeader = `${tokenType ?? "Bearer"} ${token}`;
    setCookie("token", authHeader);
    setToken(authHeader);
  };

  const handleLogout = () => {
    delCookie("token");
    setToken(null);
  };

  const value = {
    apiToken: token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    loginUrl: logingUrlWithParams,
    logoutUrl: `${logoutUrl}`,
  };

  return (
    <AuthContext.Provider value={value}>
      {headerSet && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
