import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page.jsx";
import "./index.css";
import Root from "./routes/root.jsx";
import EditorPage from "./routes/editor.jsx";
import CallbackPage, { loader as callbackLoader } from "./routes/callback.jsx";
import { loader as documentLoader } from "./components/TiptapCollab.jsx";
import reportWebVitals from "./reportWebVitals.js";
import EditorRoot, {
  loader as editorLoader,
  action as editorAction,
} from "./routes/editor-root.jsx";
import { ReactComponent as LogoNamed } from "./svg/1.svg";
import { AuthProvider } from "./hooks/useAuth.jsx";
import { AxiosInterceptor } from "./lib/unicornAPI.jsx";

const ContentZero = () => {
  return (
    <div className="container mx-auto text-center w-full mt-12">
      <p>Powered by</p>
      <LogoNamed className="mx-auto h-48 mt-4" />
      <p className="text-xs">
        Quadruple<span style={{ fontFeatureSettings: '"ss06"' }}>Y</span> Inc.
        &#169; All rights reserved
      </p>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "onebit0fme",
        element: <EditorRoot />,
        loader: editorLoader,
        action: editorAction,
        children: [
          {
            index: true,
            element: <ContentZero />,
          },
          {
            path: ":documentName",
            element: <EditorPage />,
            loader: documentLoader,
          },
        ],
      },
      {
        path: "callback",
        element: <CallbackPage />,
        loader: callbackLoader,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <AxiosInterceptor>
        <RouterProvider router={router} />
      </AxiosInterceptor>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
