import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";

import { AgentList } from "./components/AgentList.jsx";

const agentSuggestion = {
  items: ({ query }) => {
    return [
      { id: "GPT", label: "GPT" },
      { id: "(GPT 3.5 Turbo)", label: "GPT 3.5 Turbo" },
      { id: "(GPT 4)", label: "GPT 4" },
      { id: "(GPT 4 Turbo)", label: "GPT 4 Turbo" },
      { id: "(GPT 4o)", label: "GPT 4o" },
      { id: "(Command Light)", label: "Command Light" },
      { id: "Command", label: "Command" },
      { id: "(Command R)", label: "Command R" },
      { id: "(Command R+)", label: "Command R+" },
    ]
      .filter((item) =>
        item.label.toLowerCase().startsWith(query.toLowerCase()),
      )
      .slice(0, 5);
  },

  render: () => {
    let reactRenderer;
    let popup;

    return {
      onStart: (props) => {
        if (!props.clientRect) {
          return;
        }

        reactRenderer = new ReactRenderer(AgentList, {
          props,
          editor: props.editor,
        });

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  },
};

export default agentSuggestion;
