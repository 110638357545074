import React, { useEffect } from "react";
import {
  Outlet,
  useNavigation,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export async function loader({ request, params }) {
  const authParams = new URLSearchParams(window.location.hash.slice(1));
  const token = authParams.get("access_token") ?? "";
  const tokenType = authParams.get("token_type") ?? "";
  const state = authParams.get("state") ?? "";

  return { token, tokenType, state };
}

const CallbackPage = () => {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { onLogin } = useAuth();
  const { token, tokenType, state } = useLoaderData();

  useEffect(() => {
    if (!!token) {
      onLogin({ token, tokenType });
      navigate("/onebit0fme");
    }
  }, [token, tokenType]);

  return (
    <>
      <div className={navigation.state === "loading" ? "blur-sm" : "bg-gray"}>
        <h1>Unauthenticated</h1>
      </div>
    </>
  );
};

export default CallbackPage;
