import { Fragment, useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  Input,
} from "@headlessui/react";
import clsx from "clsx";
import axios from "axios";
import { Outlet, Form, Link, NavLink, useLoaderData } from "react-router-dom";
import unicornAPI from "../lib/unicornAPI";
import {
  logingUrlWithParams,
  assertLoginError,
  clientCall,
} from "../hooks/useAuth";

import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { ReactComponent as Logo } from "../svg/2-contrast.svg";

const DOCUMENTS_URL = `${process.env.REACT_APP_UNICORN_URL}/document`;

export async function get_documents({ q, xq }) {
  const data = await clientCall(
    unicornAPI.get(
      `/documents?q=${encodeURIComponent(q)}&xq=${encodeURIComponent(xq)}`,
    ),
  );
  return { documents: data?.documents ?? [] };
}

export async function create_document(documentName) {
  const data = await clientCall(
    unicornAPI.post(`${DOCUMENTS_URL}/${encodeURIComponent(documentName)}`, {}),
  );
  return { exists: data?.exists };
}

export default function DocList() {
  const { documents, q, xq } = useLoaderData();
  const [docs, setDocs] = useState(documents);
  const [query, setQuery] = useState(q);
  const [antiQuery, setAntiQuery] = useState(xq);

  // const [newDocName, setNewDocName] = useState("");

  // const refreshList = () => {
  //   // axios.get(`${DOCUMENTS_URL}s/list`).then((response) => {
  //   //   setDocs(response.data.documents);
  //   // }).catch((error) => {
  //   //     console.log(error);
  //   //   });
  //   get_documents().then((data) => {
  //     console.log(data)
  //     setDocs(data.documents);
  //   }).catch((error) => {
  //     console.log(error);
  //   })
  // }

  useEffect(() => {
    // refreshList();
    setDocs(documents);
    setQuery(q);
    setAntiQuery(xq);
  }, [documents, q, xq]);

  // const handleNewDoc = () => {
  //   console.log("Want a new doc");
  //   axios.post(`${DOCUMENTS_URL}/${encodeURIComponent(newDocName)}`, {}).then((response) => {
  //     console.log("Created", response.data);
  //     refreshList();
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // }

  // const onNewKeyPress = (e) => {
  //   if(e.key == 'Enter' && newDocName.length){
  //       handleNewDoc();
  //       setNewDocName("");
  //     }
  // }

  return (
    <div className="container mx-auto pb-4">
      <Form role="search">
        <Input
          className={clsx(
            "block w-full rounded-lg border-none bg-white py-1.5 px-3 text-sm/6 text-black",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2",
          )}
          placeholder="Search"
          name="q"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          defaultValue={q}
        />
        <Input hidden name="xq" value={antiQuery} />
        <Input type="submit" hidden />
      </Form>
      <div className="grid grid-rows-4 grid-flow-col gap-1 m-4">
        {docs.map((doc) => (
          <NavLink
            to={`/onebit0fme/${encodeURIComponent(doc.name)}`}
            className={({ isActive, isPending }) =>
              `text-xs mt-auto mb-auto ${isActive ? "text-green" : isPending ? "text-purple" : ""}`
            }
            key={doc.name}
          >
            {doc.name}
          </NavLink>
        ))}
        <Form method="post">
          <Input
            className={clsx(
              "rounded-lg border-none bg-white py-1.5 px-3 text-sm/6 text-black",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25",
            )}
            placeholder="New document"
            // onKeyDown={onNewKeyPress}
            // value={newDocName}
            // onChange={(e) => setNewDocName(e.target.value)}
            name="documentName"
          />
          <input type="submit" hidden />
        </Form>
      </div>
      <Form role="anti-search">
        <Input
          className={clsx(
            "block w-full rounded-lg border-none bg-white py-1.5 px-3 text-sm/6 text-black",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2",
          )}
          placeholder="Hide"
          name="xq"
          value={antiQuery}
          onChange={(e) => {
            setAntiQuery(e.target.value);
          }}
          defaultValue={xq}
        />
        <Input hidden name="q" value={query} />
        <Input type="submit" hidden />
      </Form>
    </div>
  );
}
