import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";

import Fuse from "fuse.js";

const BodyOfKnowledgeView = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState();
  // const sentances = props.mdPreview.match(/[^.\r?\n]+/g);
  const sentances = props.mdPreview
    .match(/[^\r?\n]+/g)
    .map((str) => str.replace(/^[\s#]*/, "").replace(/[\s#]*$/, ""));
  const fuse = new Fuse(sentances, {
    includeScore: true,
    isCaseSensitive: true,
  });

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value) {
      setSearchResult(
        fuse.search(e.target.value).reduce((previousValue, currentValue) => {
          return { [currentValue.item]: currentValue.score, ...previousValue };
        }, {}),
      );
    } else {
      setSearchResult({});
    }
  };

  const searchHighlighter = (props) => {
    if (
      typeof props.children === "string" ||
      props.children instanceof String
    ) {
      const sents = props.children.match(/[^\r?\n]+/g);
      return (
        <>
          {sents.map((sent) => (
            <span
              style={{
                // color: searchQuery ? `rgba(123,189,45, ${(searchResult[sent] ? 1.0 - (searchResult[sent]/4) : 0.25)}`: "rbga(0,0,0, 0.25)"
                color: searchQuery
                  ? searchResult[sent]
                    ? `rgba(123,189,45, ${1.0 - searchResult[sent] / 3})`
                    : `rgba(0,0,0, 0.2)`
                  : "rbga(0,0,0, 0.2)",
              }}
            >
              {sent + "\n"}
            </span>
          ))}
        </>
      );
    }
    return <>{props.children}</>;
  };

  return (
    <div className="container prose">
      <hr />
      <Markdown
        components={{
          p: (props) => <p>{searchHighlighter(props)}</p>,
          h1: (props) => <h1>{searchHighlighter(props)}</h1>,
          h2: (props) => <h2>{searchHighlighter(props)}</h2>,
          h3: (props) => <h3>{searchHighlighter(props)}</h3>,
          li: (props) => <li key={props.key}>{searchHighlighter(props)}</li>,
          // li: (props) => console.log(props),
        }}
      >
        {props.mdPreview}
      </Markdown>
      <input value={searchQuery} onChange={onSearchChange} />
    </div>
  );
};

export default BodyOfKnowledgeView;
