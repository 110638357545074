import React from "react";
import { Outlet, useNavigation, redirect } from "react-router-dom";
import ListDoc, { get_documents, create_document } from "../components/DocList";

export async function loader({ request }) {
  const url = new URL(request.url);
  const q = url.searchParams.get("q") || "";
  const xq = url.searchParams.get("xq") || "";
  const data = await get_documents({ q, xq });
  return { documents: data.documents, q, xq };
}

export async function action({ request, params }) {
  const formData = await request.formData();

  const data = Object.fromEntries(formData);

  const response = await create_document(data.documentName); // TODO: Error handling
  if (response.exists) {
    return redirect(`/onebit0fme/${encodeURIComponent(data.documentName)}`);
  } else {
    return redirect(`/onebit0fme`);
  }
}

const EditorRoot = () => {
  const navigation = useNavigation();

  return (
    <>
      <div className="bg-gray">
        <ListDoc />
      </div>
      <div className={navigation.state === "loading" ? "blur-sm" : ""}>
        <Outlet />
      </div>
    </>
  );
};

export default EditorRoot;
