import { default as MentionExtension } from "@tiptap/extension-mention";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { MentionBadge } from "../components/MentionBadge";

export const ClickableMention = MentionExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(MentionBadge);
  },
  parseHTML() {
    return [
      {
        tag: "clickable-mention-component",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["clickable-mention-component", mergeAttributes(HTMLAttributes)];
  },
  renderText({ options, node }) {
    return `@${node.attrs.label ?? node.attrs.id}`;
    // return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
  },
});
