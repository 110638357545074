import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { useEffect } from "react";
import axios from "axios";
import { CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import unicornAPI from "../lib/unicornAPI";
import {
  logingUrlWithParams,
  assertLoginError,
  clientCall,
} from "../hooks/useAuth";

const UNICORN_URL = `${process.env.REACT_APP_UNICORN_URL}/united-intelligence/controlled-omnifunctional-refinery-network`;

export function MentionBadge(props) {
  const { getPos, node, editor } = props;
  useEffect(() => {
    console.log(`Mention ${node.attrs.id} (${node.attrs.label}) initialized`);
    return () => {
      console.log(`Mention ${node.attrs.id} (${node.attrs.label}) deleted`);
    };
  }, []);

  console.log(props);

  const induceAgent = () => {
    unicornAPI
      .post("/invoke", {
        prosemirror_document: editor.getJSON(),
        actuation_inputs: {
          endgame: {
            steps: 0,
            solutions: 1,
          },
          registry: {
            stage: "real",
            // stage: "test",
            policy: "WeightedRandomPolicy",
            acting_agent: {
              cls: "EpsilonGreedyAgent",
              args: {
                active: true,
                initial_weight: 1.0,
              },
            },
            learning_agent: null,
          },
          ctx: {
            planner_model_id: "gpt-3.5-turbo",
            embedding_model_id: "text-embedding-3-small",
            // embedding_model_id: "random-128",
          },
          seed: 35,
          mangame: {
            actuations: [[501, 12]],
          },
        },
        invocation_inputs: {
          pos: getPos(),
        },
      })
      .then((response) => {
        console.log(response.data);
        editor
          .chain()
          .focus()
          .command(({ tr }) => {
            tr.insertText(response.data.response_node);
            return true;
          })
          .run();
      })
      .catch((error) => {
        assertLoginError(error);
      });
  };

  return (
    <NodeViewWrapper className="inline w-fit">
      <button
        className="background-transparent font-bold uppercase px-3 text-xs outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        onClick={induceAgent}
      >
        <CursorArrowRippleIcon
          className="h-4 inline-block outline-green text-green"
          aria-hidden="true"
        />{" "}
        @{node.attrs.label || node.attrs.id}
      </button>
    </NodeViewWrapper>
  );
}
