import { Outlet, useNavigation } from "react-router-dom";
import "../App.scss";
import Nav from "../components/Nav";

const Root = () => {
  const navigation = useNavigation();

  return (
    <div className="App">
      <Nav />
      <div className={navigation.state === "loading" ? "blur-sm" : ""}>
        <Outlet />
      </div>
      <div className="relative h-36 bg-gray">
        <div className="absolute bottom-4 w-full">
          <p className=" text-center">
            <span style={{ fontFeatureSettings: '"ss06"' }}>Y</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Root;
