import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { getCookie } from "../lib/cookies";

export const unicornClient = axios.create({
  baseURL: process.env.REACT_APP_UNICORN_URL,
});
unicornClient.defaults.headers.common["Authorization"] = getCookie("token");

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const { loginUrl } = useAuth();

  useEffect(() => {
    const responseIntercept = (response) => {
      return response;
    };

    const errResponseIntercept = (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        // This isn't guaranteed because axios skips interceptors when response does not have content.
        window.location = loginUrl;
      }

      return Promise.reject(error);
    };

    const responseInterceptor = unicornClient.interceptors.response.use(
      responseIntercept,
      errResponseIntercept,
    );

    setIsSet(true);

    return () =>
      setIsSet(false) &&
      unicornClient.interceptors.response.eject(responseInterceptor);
  }, [loginUrl]);

  return isSet && children;
};

export default unicornClient;
export { AxiosInterceptor };
