export const setCookie = (name, value) => {
  console.log("Setting cookie...");
  document.cookie = `${name}=${value};SameSite=Strict`;
};

export const delCookie = (name) => {
  document.cookie = `${name}=none;expires=0;SameSite=Strict`;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  let t;
  if (parts.length === 2) {
    t = parts.pop().split(";").shift();
  }

  return t;
};
